import {
  createContext, useState,
  useEffect,
  useContext,
} from 'react'
import PropTypes from 'prop-types'

import { NotificationContext } from 'app/contexts/Notifications'

export const MentionNotificationsContext = createContext(null)

const MentionNotifications = ({ children }) => {
  const { notification } = useContext(NotificationContext)
  const [mentionNotifications, setMentionNotifications] = useState([])

  useEffect(() => {
    if (notification?.type === 'galaxy-mentions-notifications') {
      setMentionNotifications(notification.data)
    }
  }, [notification])

  return (
    <MentionNotificationsContext.Provider value={{
      mentionNotifications,
      setMentionNotifications,
    }}>
      {children}
    </MentionNotificationsContext.Provider>
  )
}

MentionNotifications.propTypes = {
  children: PropTypes.any,
}

export default MentionNotifications
